html, body {
    margin: 0;
    padding: 0;
    height: 100%;
}

#root {
    height: 100%;
}

.App {
    font-family: sans-serif;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.centered {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    flex-grow: 1;
}
